import { navigate } from 'gatsby'

export default function RedirectToParent({ location }) {
  if (typeof window !== 'undefined' && location) {
    const path = location.pathname.split('/').filter(Boolean)
    path.pop()
    navigate(`/${path.join('/')}`)  
  }
  return null
}
